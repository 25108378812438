import logo from './splash.png';
import './App.css';

function App() {
  return (
    <div className="container">
      <img src={logo} style={{ height: '75%', width: '75%' }} alt="Indexify" />
    </div>
  );
}

export default App;
